import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import 'moment/locale/nl-be';
import moment from 'moment';
import '../stylesheets/calendar.sass';

class Calendar extends Component {
  componentDidMount() {
    moment.locale('nl-be');
  }

  render() {
    const { games, title, small, id } = this.props;

    if (!games) return null;

    const gamesWithMoment = games.map(game => ({
      ...game,
      moment: moment(`${game.datumString}-${game.beginTijd}`, 'DD-MM-YYYY-HH.mm'),
    }));

    const sortedGames = gamesWithMoment.sort((prev, next) => (prev.moment < next.moment ? -1 : 1));

    // only show first date if date is the same for the next game

    // const uniqueDates = sortedGames.map((game, index) => {
    //   const currentDateString = sortedGames[index].datumString;
    //   const previousDateString = sortedGames[index - 1] ? sortedGames[index - 1].datumString : null;
    //   if (previousDateString && currentDateString === previousDateString) {
    //     return { ...game, moment: null };
    //   }
    //   return game;
    // });

    return (
      games && (
        <StaticQuery
          query={graphql`
            query {
              allContentfulTeamLogos {
                edges {
                  node {
                    logos {
                      file {
                        contentType
                        url
                      }
                      title
                      fixed(width: 40) {
                        ...GatsbyContentfulFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <div className={`calendar${small ? ' small' : ''} `} id={id}>
              {title && <h2>{title}</h2>}
              <div className="games">
                {sortedGames.map(game => {
                  const homeLogo = data.allContentfulTeamLogos.edges[0].node.logos.filter(
                    logo => logo.title === game.tTGUID.slice(0, 8)
                  )[0];
                  const awayLogo = data.allContentfulTeamLogos.edges[0].node.logos.filter(
                    logo => logo.title === game.tUGUID.slice(0, 8)
                  )[0];
                  const homeTeamResult = game.uitslag.split('-')[0];
                  const awayTeamResult = game.uitslag.split('-')[1];
                  return (
                    <div className="game" key={game.guid}>
                      <div className="teamDetails">
                        <div
                          className={`team home ${homeTeamResult > awayTeamResult ? 'win' : ''} ${
                            game.tTNaam.toLowerCase().includes('oxaco') ? 'oxaco' : ''
                          }`}
                        >
                          <div className="teamLogo">
                            {homeLogo &&
                              (homeLogo.file.contentType.includes('svg') ? (
                                <img src={homeLogo.file.url} alt="" />
                              ) : (
                                <Image fixed={homeLogo.fixed} />
                              ))}
                          </div>
                          <div className="teamName">{game.tTNaam}</div>
                          <div className="teamResult">{homeTeamResult}</div>
                        </div>
                        <div
                          className={`team away ${awayTeamResult > homeTeamResult ? 'win' : ''} ${
                            game.tUNaam.toLowerCase().includes('oxaco') ? 'oxaco' : ''
                          }`}
                        >
                          <div className="teamLogo">
                            {awayLogo &&
                              (awayLogo.file.contentType.includes('svg') ? (
                                <img src={awayLogo.file.url} alt="" />
                              ) : (
                                <Image fixed={awayLogo.fixed} />
                              ))}
                          </div>
                          <div className="teamName">{game.tUNaam}</div>
                          <div className="teamResult">{awayTeamResult}</div>
                        </div>
                      </div>
                      {game.moment && (
                        <div className="time_location">
                          <div className="time">{`${game.moment.format('dd D/MM HHumm')}`}</div>
                          <div className="accomodation">{game.accNaam}</div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        />
      )
    );
  }
}

export default Calendar;
